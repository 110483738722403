import { useMemo } from "react";
import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { toNullableJSON } from "@/utils/stringUtils";
import { fetchSubjects, groupSubjectsByCategory } from "@/hooks/subjects/utils";
import { fromEntries } from "@/utils/genericUtils";
import { Subject } from "@knowt/syncing/graphql/schema";
import { platform } from "@/platform";
import { SUBJECT_TO_CATEGORY, TOPIC_TO_SUBJECT } from "./constants";

export const useRawSubjects = (isEnabled = true) => {
    const { data: subjects } = useSWRImmutable<Subject[]>(isEnabled && ["subjects"], async () => {
        const subjects = await fetchSubjects();
        try {
            return subjects
                ?.map((s: Subject) => {
                    let topics: string[] = [];
                    try {
                        topics = (s.topics && Object.keys(JSON.parse(toNullableJSON(s.topics)))) || [];
                    } catch {
                        // ignored
                    }
                    return { ...s, topics };
                })
                .sort((a: Subject, b: Subject) => {
                    const subjectA = a?.subject?.toUpperCase() || "";
                    const subjectB = b?.subject?.toUpperCase() || "";
                    return subjectA.localeCompare(subjectB);
                });
        } catch (e) {
            const { log, report } = await platform.analytics.logging();

            report(e, "useRawSubjects", { subjects });
            log("ERROR IN SUBJECT PARSING", e);
        }
    });

    return subjects;
};

export const useTopicsBySubject = (subject: string) => {
    const subjects = useRawSubjects();

    const { data: topics } = useSWRImmutable([subjects && "topicsBySubject", subject], ([_, subject]) => {
        const unsortedTopics = subjects?.find?.(s => s.subject === subject)?.topics || [];
        if (typeof unsortedTopics === "string") return [unsortedTopics];
        return unsortedTopics.sort();
    });

    return topics;
};

export const useCategoryBySubject = (subject: string | null | undefined) => {
    return SUBJECT_TO_CATEGORY[subject || ""] || null;
};

export const useSubjectByTopic = (topic: string | null | undefined) => {
    return TOPIC_TO_SUBJECT[topic || ""] || null;
};

export const useSubjectsAsObject = () => {
    const rawSubjects = useRawSubjects();

    const { data: subjectsObj } = useSWRImmutable(["subjectsAsObject", rawSubjects], () => {
        return fromEntries(rawSubjects?.map(({ subject, ...rest }) => [subject, { subject, ...rest }]));
    });

    return subjectsObj;
};

/**
 * returns all the subjects grouped by category
 * @returns Object<{category: {subjects: Array, ...categoryData}}>
 */
export const useAllSubjectsByCategories = (initialCategories?: string) => {
    const subjects = useRawSubjects();

    return useMemo(() => {
        if (!subjects) return initialCategories;
        return groupSubjectsByCategory(subjects);
    }, [subjects, initialCategories]);
};
