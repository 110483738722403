import React from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";

type TaggingStyleModifierProps = {
    inputStyle: React.CSSProperties; // for styling the input
    ulStyle: React.CSSProperties; // for styling the suggestion.ts menu
    liStyle: React.CSSProperties; // for styling the item of the suggestion.ts menu
    liHeaderStyle: React.CSSProperties; // for styling the header of the item in the suggestion.ts menu
    labelStyle: React.CSSProperties; // for styling the label
    inputProps: React.InputHTMLAttributes<HTMLInputElement> & { sx?: SxProps }; // for styling the input
};

// Use this context to modify the style of the TaggingAutocomplete from outside of (School|Grade|Subject|*)Tagging components
const TaggingStyleModifierContext = createContext<TaggingStyleModifierProps>({
    inputStyle: {},
    ulStyle: {},
    liStyle: {},
    liHeaderStyle: {},
    labelStyle: {},
    inputProps: {},
});

export const TaggingStyleModifier = ({
    children,
    inputStyle = {},
    ulStyle = {},
    liStyle = {},
    liHeaderStyle = {},
    labelStyle = {},
    inputProps = {},
}: { children: React.ReactNode } & Partial<TaggingStyleModifierProps>) => {
    return (
        <TaggingStyleModifierContext.Provider
            value={{ inputStyle, ulStyle, liStyle, liHeaderStyle, labelStyle, inputProps }}>
            {children}
        </TaggingStyleModifierContext.Provider>
    );
};

export const useTaggingStyleModifierContextSelector = <T,>(selector: (value: TaggingStyleModifierProps) => T) =>
    useContextSelector(TaggingStyleModifierContext, selector);
