import { X, LucideProps } from "lucide-react";
import CircularButton, { CircularButtonProps } from "@/components/CircularButton";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import { useResponsiveDialogContext } from "../hooks/useResponsiveDialogContext";

type IconProps = { iconProps?: LucideProps };
type ResponsiveDialogStyles = {
    style?: React.CSSProperties;
    /** styles applied only when `Dialog` is active and take precedence over `style` props */
    forDialogStyle?: React.CSSProperties;
    /** styles applied only when `SlideMenu` is active and take precedence over `style` props */
    forSlideMenuStyle?: React.CSSProperties;
};
type ButtonProps = Omit<CircularButtonProps, "children" | "sx">;
type RequiredOnClick = { onClick: (e: React.MouseEvent) => void };

type DialogCloseBtnProps = IconProps & ResponsiveDialogStyles & ButtonProps & RequiredOnClick;

const DialogCloseBtn = ({
    iconProps: _iconProps = {},
    style: _style,
    forDialogStyle,
    forSlideMenuStyle,
    ...props
}: DialogCloseBtnProps) => {
    const { isMobile } = useResponsiveDialogContext();

    // default icon styles
    const { style: iconStyle, ...iconProps } = { size: iconSizes.MD, strokeWidth: strokeWidth.normal, ..._iconProps };

    const style = {
        ..._style,
        ...(isMobile ? forSlideMenuStyle : forDialogStyle),
    };

    return (
        <CircularButton
            radius={"4rem"}
            sx={{
                backgroundColor: "transparent",
                borderColor: themeColors.neutralBlack,
                borderWidth: "1px",
                borderStyle: "solid",
                // icon styles
                "& > svg": { ...iconStyle },
                // override any style on hovered
                "&:hover": {
                    "& > svg": {
                        color: themeColors.neutralWhite,
                    },
                    backgroundColor: themeColors.neutralBlack,
                },
                ...style,
            }}
            {...props}>
            <X {...iconProps} />
        </CircularButton>
    );
};

export default DialogCloseBtn;
