import { useAllExams } from "@knowt/syncing/hooks/exams/useExams";
import TaggingAutocomplete, { TaggingLabel } from "../styled/TaggingAutocomplete";
import { FlexColumn } from "@/components/Flex";
import { OptionProps } from "@/components/InputWithMenu";
import React from "react";

export const ExamSectionTagging = ({
    examSection,
    exam,
    examUnit,
    onChange,
    containerStyle,
}: {
    examSection: string | null;
    exam: string;
    examUnit: string;
    onChange: (newExamSection: string | null) => void;
    containerStyle?: React.CSSProperties;
}) => {
    const value = { label: examSection ?? "", value: examSection ?? null };

    const examsObj = useAllExams();
    const sections = examsObj?.[exam]?.units?.[examUnit]?.sections || [];
    const sectionOptions: OptionProps[] = sections.map(s => ({ label: s as string, value: s }));

    return (
        <FlexColumn style={{ gap: "1rem", ...containerStyle }}>
            <TaggingLabel htmlFor="sectioninput">Sub-unit</TaggingLabel>
            <TaggingAutocomplete
                useAutocompleteProps={{
                    options: sectionOptions,
                    value: value,
                    onChange: async (_, newValue, reason) => {
                        if (reason === "selectOption") {
                            if (newValue && newValue.value !== value.value) {
                                if (typeof newValue.value === "string") {
                                    onChange(newValue.value);
                                }
                            }
                        }

                        if (reason === "clear") {
                            onChange(null);
                        }
                    },
                }}
                inputProps={{ placeholder: "Pick a sub-unit tag", id: "sectioninput" }}
            />
        </FlexColumn>
    );
};

export default ExamSectionTagging;
