import useDebounce from "react-use/lib/useDebounce";
import { runESSuggestions } from "@/fetchFunctions/elasticsearch";
import { useRawExams } from "@/hooks/exams/useExams";
import { useRawSubjects } from "@/hooks/subjects/useSubjects";
import { Exam, Subject } from "@knowt/syncing/graphql/schema";
import { useEffect, useState } from "react";

type SuggestionType = "USER" | "VERIFIED_SCHOOL" | "NOTE" | "SUBJECT" | "EXAM";
type SuggestionData = {
    EXAM: { category: string };
    SUBJECT: { category: string };
    VERIFIED_SCHOOL: { schoolId: string; name: string; address: { city: string; zip: string } };
};
export type Suggestion<T extends SuggestionType = SuggestionType> = {
    text: string;
    type: T;
    data: SuggestionData[T];
};

export type SuggestedSchool = Suggestion<"VERIFIED_SCHOOL">;

export const useSearchSuggestion = ({
    query,
    max = 10,
    isEnabled = true,
}: {
    query: string;
    max: number;
    isEnabled: boolean;
}) => {
    const subjects = useRawSubjects(isEnabled);
    const exams = useRawExams(isEnabled);

    const [data, setData] = useState<Suggestion[]>([]);

    useEffect(() => {
        const suggestions = [
            getExamSuggestion(exams as Exam[], query),
            getSubjectSuggestion(subjects as Subject[], query),
        ]
            .flat()
            .slice(0, max)
            .filter(a => a);

        setData(suggestions);
    }, [subjects, exams, max, query]);

    return { suggestions: data };
};

export const getSubjectSuggestion = (subjects: Subject[], query: string): Suggestion<"SUBJECT">[] => {
    const subjectSuggest: Suggestion<"SUBJECT">[] = (subjects || [])
        .filter(s => s?.subject?.toLowerCase().startsWith(query))
        .map(s => ({ text: s.subject as string, type: "SUBJECT", data: { category: s.category as string } }));

    return subjectSuggest;
};

export const getExamSuggestion = (exams: Exam[], query: string): Suggestion<"EXAM">[] => {
    const examSuggest: Suggestion<"EXAM">[] = (exams || [])
        .filter(e => e.name.toLowerCase().startsWith(query))
        .map(e => ({ text: e.name, type: "EXAM", data: { category: e.type } }));

    return examSuggest;
};

export const useVerifiedSchoolSuggestions = ({
    query,
    country,
    state,
}: {
    query: string;
    country?: string;
    state?: string;
}) => {
    const [data, setData] = useState<SuggestedSchool[]>([]);

    useDebounce(
        () => {
            if (!state || !country) return;

            getVerifiedSchoolSuggestion({ query, country, state }).then(suggestions => {
                setData(suggestions);
            });
        },
        100,
        [query, country, state]
    );

    return data;
};

export const getVerifiedSchoolSuggestion = async ({
    query,
    country,
    state,
}: {
    query: string;
    country: string;
    state: string;
}): Promise<SuggestedSchool[]> => {
    const schoolSuggestionsRaw = (
        await runESSuggestions({
            queryPhrase: query,
            queryFields: ["name.suggest"],
            searchIndex: ["VERIFIED_SCHOOL"],
            returnFields: ["name", "schoolId", "country", "state", "address.city", "address.zip"],
            pagesize: 20,
            contexts: {
                "name.suggest": {
                    state,
                },
            },
        })
    )?.["name.suggest"]?.[0]?.options;

    const schoolSuggestions: Suggestion<"VERIFIED_SCHOOL">[] = schoolSuggestionsRaw
        // context filters are OR conditions. so since state is (mostly) unique across all places, we can filter by state, and then post-filter by country (should only be needed in very limited cases)
        .filter(sug => sug._source.country === country)
        ?.map(school => {
            return { text: school.text, type: "SCHOOL", data: { schoolId: school._id, ...school._source } };
        });

    return schoolSuggestions;
};
