import { listExamsMetadata } from "@/graphql/customQueries";
import { getExam, listExams } from "@/graphql/queries";
import { fetchSubjectsByCategory } from "@/hooks/subjects/utils";
import { platform } from "@/platform";
import { Exam } from "@knowt/syncing/graphql/schema";
import { client } from "@/utils/SyncUtils";
import { pick } from "@/utils/dataCleaning";
import { fromEntries } from "@/utils/genericUtils";
import { ExamMetadata } from "@/graphql/customSchema";
import { memoize } from "nextjs-better-unstable-cache";
import { TIME_SECONDS } from "@/utils/dateTimeUtils";

export const EXAMS_BUCKET = "https://knowt-exam-images.s3.amazonaws.com/";

export const fetchExams = memoize(
    async ({ slim = false, type = undefined }: { slim?: boolean; type?: string } = {}) => {
        return client
            .query({
                query: slim ? listExamsMetadata : listExams,
                variables: { input: { type } },
            })
            .then(({ data }) => data.listExams.items)
            .catch(async e => {
                const { report } = await platform.analytics.logging();
                report(e, "fetchExams");
                return [] as Exam[];
            });
    },
    {
        persist: true,
        duration: TIME_SECONDS.DAY,
        revalidateTags: ({ slim, type }) => ["fetch-exams", slim.toString(), type.toString()],
        log: ["dedupe", "datacache"],
        logid: "fetch-exams",
    }
);

export const fetchExam =
    // memoize(
    async ({ name }: { name: string }) => {
        return await client
            .query({
                query: getExam,
                variables: { input: { exam: name } },
            })
            .then(({ data }) => data.getExam)
            .catch(async error => {
                const { report } = await platform.analytics.logging();
                report(error, "fetchExam", { exam: name });
                throw error;
            });
    };
//     ,
//     {
//         persist: true,
//         duration: TIME_SECONDS.DAY,
//         revalidateTags: ({ name }) => ["exam", name],
//         log: ["dedupe", "datacache"],
//         logid: "get-exam",
//     }
// );

/**
 * returns all the subjects grouped by category
 * @returns Object<{category: {subjects: Array, ...categoryData}}>
 */
export const fetchExamsByType = async () => {
    const exams = await fetchExams({ slim: true });
    return groupExamsByType(exams);
};

export const fetchExamsByCategory = async ({ type }: { type: string }) => {
    const exams = await fetchExams({ slim: true, type });
    const subjectsByCategory = await fetchSubjectsByCategory();
    return groupExamsByCategory(exams, subjectsByCategory);
};

export const fetchFeaturedExamCategories = async ({ type }: { type: string }) => {
    const examsByCategory = await fetchExamsByCategory({ type });
    return getFeaturedCategories(examsByCategory);
};

export const fetchFeaturedExamTypes = async () => {
    const examsByType = await fetchExamsByType();
    return getFeaturedCategories(pick(examsByType, "AP"));
};

const getFeaturedCategories = examsByCategory => {
    return Object.values(examsByCategory).map(backendToFrontendFeaturedCategory);
};

export const groupExamsByCategory = (exams: Exam[], categories) => {
    const categoriesWithExams = (exams || []).map(({ category, type }) => ({
        ...categories[category],
        type,
        category,
        exams: exams.filter(exam => exam.category === category),
    }));

    return fromEntries(categoriesWithExams.map(item => [item.category || "", item]));
};

export const groupExamsByType = (exams: (ExamMetadata | Exam)[]) => {
    const typesWithExams = (exams || []).map(({ type }) => ({
        type,
        exams: exams.filter(exam => exam.type === type),
    }));

    return fromEntries(typesWithExams.map(item => [item.type, item]));
};

export type FrontendFeaturedCategory = {
    category: string;
    image: string;
    subjects: Array<{ subject: string; route: string; image: string }>;
    route: string;
};

// TODO: We should fix this code (almost shared between subjects and exams),
//  but it gets really confusing when we are using subjects as the key to map exams into
const backendToFrontendFeaturedCategory = ({
    type,
    category,
    exams,
}: {
    type: ExamMetadata["type"];
    category: ExamMetadata["category"];
    exams: (ExamMetadata | Exam)[];
}): FrontendFeaturedCategory => ({
    // we would use category if we're on the /exam/{type} page, and type on the general /explore or /exam page
    category: category || type,
    image: getImageLink(category || type),
    // TODO: rename to items
    subjects: exams.map(({ name }) => ({
        subject: name,
        route: `/exams/${type}/${examNameToUrlComponent(name)}`,
        image: getImageLink(name),
    })),
    route: `/exams/${type}`,
});

// in the url, we do some replaces to make the url in a readable format
// ": " -> "_"
// " - " -> "="
// "-" -> "."
// " " -> "-"
// "A-Level Biology: Edexcel" -> "A.Level-Biology_Edexcel"

export const examNameToUrlComponent = (name: string) =>
    encodeURIComponent((name || "").replace(/: /g, "_").replace(/ - /g, "=").replace(/-/g, ".").replace(/ /g, "-"));
export const examUrlComponentToName = (urlComponent: string) =>
    decodeURIComponent(urlComponent).replace(/_/g, ": ").replace(/-/g, " ").replace(/\./g, "-").replace(/=/g, " - ");

export const examTypeNameToUrlComponent = (_name: string) => {
    const name = _name.includes("-") ? _name : _name.toUpperCase();
    return (name || "").replace(/: /g, "_").replace(/ - /g, "=").replace(/-/g, ".").replace(/ /g, "-");
};
export const examTypeUrlComponentToName = (urlComponent: string) => {
    const _type = examUrlComponentToName(urlComponent);
    return _type;
};

export const getExamNameAndType = (param: string) =>
    param
        ? {
              type: param.split("-").pop() as "notes" | "flashcards" | string,
              param: examUrlComponentToName(param.split("-").slice(0, -1).join("-")),
          }
        : { type: undefined, param: undefined };

export const getFileName = (name: string) => {
    return (
        name
            .toLowerCase()
            .replace(/: /g, "_")
            .replace(/ - /g, "_")
            .replace(/ /g, "_")
            .replace(/-/g, "_")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/,/g, "")
            .replace(/&/g, "and") + ".svg"
    );
};

export const getImageLink = (name?: string | null) => {
    if (!name) return undefined;
    return EXAMS_BUCKET + getFileName(name);
};
