import React from "react";
import { useTopicsBySubject } from "@knowt/syncing/hooks/subjects/useSubjects";
import TaggingAutocomplete, { TaggingLabel } from "../styled/TaggingAutocomplete";
import { useTaggingStyleModifierContextSelector } from "../../hooks/useTaggingStyleModifierContext";
import { OptionProps } from "@/components/InputWithMenu";
import { FlexColumn } from "@/components/Flex";

export const TopicTagging = ({
    topic,
    subject,
    onChange,
    containerStyle,
}: {
    topic: string | null;
    subject: string;
    onChange: (newTopic: string | null) => void;
    containerStyle?: React.CSSProperties;
}) => {
    const labelStyle = useTaggingStyleModifierContextSelector(state => state.labelStyle);
    const inputProps = useTaggingStyleModifierContextSelector(state => state.inputProps);

    const value = { label: topic ?? "", value: topic ?? null };

    const topics = useTopicsBySubject(subject || "");
    const topicOptions: OptionProps[] = topics?.map?.(t => ({ label: t, value: t })) || [];

    return (
        <FlexColumn style={{ gap: "1rem", ...containerStyle }}>
            <TaggingLabel style={labelStyle} htmlFor="topicinput">
                Topic
            </TaggingLabel>
            <TaggingAutocomplete
                useAutocompleteProps={{
                    options: topicOptions,
                    value: value,
                    onChange: async (_, newValue, reason) => {
                        if (reason === "selectOption") {
                            if (newValue && newValue.value !== value.value) {
                                if (typeof newValue.value === "string") {
                                    onChange(newValue.value);
                                }
                            }
                        }

                        if (reason === "clear") {
                            onChange(null);
                        }
                    },
                }}
                inputProps={{
                    placeholder: "Type a topic",
                    id: "topicinput",
                    ...inputProps,
                }}
            />
        </FlexColumn>
    );
};

export default React.memo(TopicTagging);
