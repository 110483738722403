import { createContext, useContext } from "react";

const ResponsiveDialogContext = createContext({ isMobile: false });

/**
 * Provider for `ResponsiveDialog` to let it's children knows that `isMobile` is truthy
 */
export const ResponsiveDialogContextProvider = ({
    isMobile,
    children,
}: {
    isMobile: boolean;
    children: React.ReactNode;
}) => {
    return <ResponsiveDialogContext.Provider value={{ isMobile }}>{children}</ResponsiveDialogContext.Provider>;
};

/**
 * get `isMobile` value to know wheter `SlideMenu` is active or not.
 */
export const useResponsiveDialogContext = () => {
    const context = useContext(ResponsiveDialogContext);
    if (context === undefined) {
        throw new Error("useResponsiveDialogContext must be used within a ResponsiveDialogContextProvider");
    }
    return context;
};
