import useAutocomplete, { AutocompleteGroupedOption, UseAutocompleteProps } from "@mui/base/useAutocomplete";
import React, { Fragment, forwardRef } from "react";

// eslint-disable-next-line no-restricted-imports
import type { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { themeColors } from "@/utils/themeColors";
import { Flex } from "@/components/Flex";
import { ChevronDown } from "lucide-react";
import { iconSizes } from "@/utils/iconProps";

export type OptionProps = {
    label: string;
    value: string;
    node?: React.ReactNode;
    group?: string;
};

export type InputWithMenuProps = {
    rootProps?: React.InputHTMLAttributes<HTMLDivElement>;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement> & { sx?: SxProps };
    ulProps?: React.HTMLAttributes<HTMLUListElement> & { sx?: SxProps };
    liProps?: React.HTMLAttributes<HTMLLIElement> & { sx?: SxProps };
    liHeaderProps?: React.HTMLAttributes<HTMLLIElement> & { sx?: SxProps };
    noOptionsText?: React.ReactNode;
    useAutocompleteProps: UseAutocompleteProps<OptionProps, false, false, false>;
    adornment?: React.ReactNode;
};

/**
 * Barebone input-with-menu using MUI's useAutocomplete hook
 * - disable autoselect by setting `autoHighlight` to false
 * - pass `null` to `noOptionsText` to disable it
 * - we force the `useAutocomplete.options` structure to be always `{ label: string; value: unknown }`
 * - `ulProps` is props of the listbox (suggestion.ts menu body)
 * - `liProps` is props of the listbox option (item within the suggestion.ts menu body)
 * - `liHeaderProps` is props of the listbox option header (group name of the items if `option.group` is defined)
 */
const InputWithMenu = ({
    rootProps,
    inputProps,
    ulProps,
    liProps,
    liHeaderProps,
    noOptionsText = "No available options",
    useAutocompleteProps,
    adornment = <ChevronDown size={iconSizes.SM} fill={"currentColor"} strokeWidth={0} />,
}: InputWithMenuProps) => {
    const { inputValue, getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions, expanded } =
        useAutocomplete({
            id: "use-autocomplete-input-with-menu",
            getOptionLabel: option => option.label,
            autoHighlight: true,
            isOptionEqualToValue: (option, value) => value && option.value === value.value,
            ...useAutocompleteProps,
        });

    const isOptionsEmpty = (() => {
        if (useAutocompleteProps.options[0]?.group) {
            return (groupedOptions as AutocompleteGroupedOption<OptionProps>[]).length === 0;
        }
        return (groupedOptions as OptionProps[]).filter(option => option.label).length === 0;
    })();

    const renderOptions = () => {
        if (useAutocompleteProps.options[0]?.group) {
            return (
                // options with group (has header list)
                <Ul {...getListboxProps()} {...ulProps}>
                    {(groupedOptions as AutocompleteGroupedOption<OptionProps>[]).map(
                        ({ key: groupKey, index: groupIdx, group, options }, i) => {
                            return (
                                <Fragment key={groupKey + groupIdx + i}>
                                    <LiHeader {...liHeaderProps}>{group}</LiHeader>
                                    {options.map((option, optionIdx) => {
                                        const optionKey = group + optionIdx.toString() + option.value + option.label;
                                        return (
                                            <Li
                                                {...getOptionProps({ option, index: groupIdx + optionIdx })}
                                                key={optionKey}>
                                                {option.node || option.label}
                                            </Li>
                                        );
                                    })}
                                </Fragment>
                            );
                        }
                    )}
                </Ul>
            );
        }

        return (
            <Ul {...getListboxProps()} {...ulProps}>
                {(groupedOptions as OptionProps[]).map((option, index) => (
                    <Li
                        {...getOptionProps({ option, index })}
                        {...liProps}
                        key={index.toString() + option.value + option.label}>
                        {option.node || option.label}
                    </Li>
                ))}
            </Ul>
        );
    };

    return (
        <div {...getRootProps()} {...rootProps} style={{ position: "relative", ...rootProps?.style }}>
            <Input
                {...getInputProps()}
                {...inputProps}
                sx={{
                    ...inputProps?.sx,
                    paddingRight: "4rem",
                }}
            />

            {
                // input adornment
                adornment ? (
                    <Flex
                        style={{
                            position: "absolute",
                            right: "1.5rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                        }}>
                        {adornment}
                    </Flex>
                ) : null
            }

            {!isOptionsEmpty ? renderOptions() : null}
            {/**
             * NOTE:
             * somehow `expanded` is `null` when the popup is opened and `false` when the popup closed by "enter"
             */}
            {isOptionsEmpty && inputValue && noOptionsText && expanded == null ? (
                // no options text
                <Ul {...getListboxProps()} {...ulProps}>
                    {
                        <Li {...liProps} style={{ whiteSpace: "initial" }}>
                            {noOptionsText}
                        </Li>
                    }
                </Ul>
            ) : null}
        </div>
    );
};

export default InputWithMenu;

const Input = forwardRef<HTMLInputElement, { sx?: SxProps } & React.HTMLAttributes<HTMLInputElement>>(
    ({ sx, ...props }, ref) => {
        return (
            <Box
                ref={ref}
                component={"input"}
                {...props}
                sx={{
                    width: "100%",
                    fontFamily: "var(--knowt-font)",
                    backgroundColor: themeColors.background,
                    fontSize: "inherit",
                    color: themeColors.neutralBlack,
                    outline: "none",
                    border: "none",
                    padding: 0,
                    ...sx,
                }}
            />
        );
    }
);

Input.displayName = "Input";

const Ul = forwardRef<HTMLUListElement, { sx?: SxProps } & React.HTMLAttributes<HTMLUListElement>>(
    ({ sx, ...props }, ref) => {
        return (
            <Box
                ref={ref}
                component={"ul"}
                {...props}
                sx={{
                    width: "100%",
                    zIndex: 1,
                    position: "absolute",
                    backgroundColor: themeColors.background,
                    overflow: "auto",
                    fontSize: "inherit",
                    maxHeight: "18rem",
                    "& li.Mui-focused": {
                        color: themeColors.primary,
                        cursor: "pointer",
                    },
                    "& li:active": {
                        color: themeColors.primary,
                    },
                    listStyle: "none",
                    padding: "0.64rem 0",
                    margin: 0,
                    ...sx,
                }}
            />
        );
    }
);

Ul.displayName = "Ul";

const Li = forwardRef<HTMLLIElement, { sx?: SxProps } & React.HTMLAttributes<HTMLLIElement>>(
    ({ children, sx, ...props }, ref) => {
        return (
            <Box
                ref={ref}
                component={"li"}
                {...props}
                sx={{
                    padding: "0.32rem 1.28rem",
                    margin: "0.2rem",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    ...sx,
                }}>
                {children}
            </Box>
        );
    }
);

Li.displayName = "Li";

const LiHeader = forwardRef<HTMLLIElement, { sx?: SxProps } & React.HTMLAttributes<HTMLLIElement>>(
    ({ children, sx, ...props }, ref) => {
        return (
            <Box
                ref={ref}
                component={"li"}
                {...props}
                sx={{
                    padding: "0.32rem 0.64rem",
                    margin: "0.2rem 0",
                    position: "sticky",
                    top: "-0.64rem",
                    backgroundColor: themeColors.neutral3,
                    color: themeColors.neutralWhite,
                    opacity: 1,
                    ...sx,
                }}>
                {children}
            </Box>
        );
    }
);

LiHeader.displayName = "LiHeader";
