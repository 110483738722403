import { useRawSubjects } from "@knowt/syncing/hooks/subjects/useSubjects";
import React from "react";
import TaggingAutocomplete, { TaggingLabel } from "../styled/TaggingAutocomplete";
import { OptionProps } from "@/components/InputWithMenu";
import { FlexColumn } from "@/components/Flex";

export const SubjectTagging = ({
    subject,
    onChange,
    containerStyle,
}: {
    subject: string | null;
    onChange: (newSubject: string | null) => void;
    containerStyle?: React.CSSProperties;
}) => {
    const value = { label: subject ?? "", value: subject ?? null };

    const rawSubjectList = useRawSubjects();

    const subjectOptions: OptionProps[] =
        rawSubjectList?.filter(s => s?.subject)?.map(s => ({ label: s.subject as string, value: s.subject })) || [];

    return (
        <FlexColumn style={{ gap: "1rem", ...containerStyle }}>
            <TaggingLabel htmlFor="subjectinput">Subject</TaggingLabel>
            <TaggingAutocomplete
                useAutocompleteProps={{
                    options: subjectOptions,
                    value: value,
                    onChange: async (_, newValue, reason) => {
                        if (reason === "selectOption") {
                            if (newValue && newValue.value !== value.value) {
                                if (typeof newValue.value === "string") {
                                    onChange(newValue.value);
                                }
                            }
                        }

                        if (reason === "clear") {
                            onChange(null);
                        }
                    },
                }}
                inputProps={{
                    placeholder: "Type a subject",
                    id: "subjectinput",
                }}
            />
        </FlexColumn>
    );
};

export default SubjectTagging;
