"use client";

import { spacing } from "@/utils/spacing";
import { useAllExams } from "@knowt/syncing/hooks/exams/useExams";
import { useSubjectsAsObject } from "@knowt/syncing/hooks/subjects/useSubjects";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { FlashcardSet, Media, Note } from "@knowt/syncing/graphql/schema";
import React, { useReducer, useState } from "react";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import CustomTagging from "./CustomTagging";
import ExamTagging from "./ExamTagging";
import ExamSectionTagging from "./ExamTagging/ExamSectionTagging";
import ExamUnitTagging from "./ExamTagging/ExamUnitTagging";
import SubjectTagging from "./SubjectTagging";
import TopicTagging from "./SubjectTagging/TopicTagging";
import { TaggingStyleModifier } from "../hooks/useTaggingStyleModifierContext";
import { themeColors } from "@/utils/themeColors";
import { TagFields } from "../types";
import LoadingAnimation from "@/components/LoadingAnimation";
import ResponsiveDialog, { DialogCloseBtn } from "@/features/ResponsiveDialog";

type TagAction =
    | {
          type:
              | "modify-school"
              | "modify-grade"
              | "modify-subject"
              | "modify-topic"
              | "modify-exam"
              | "modify-examUnit"
              | "modify-examSection";
          payload: string | null;
      }
    | { type: "modify-custom"; payload: string[] | null };

const tagReducer = (tags: TagFields, action: TagAction): TagFields => {
    switch (action.type) {
        case "modify-school": {
            return { ...tags, schoolId: action.payload, grade: null, courseId: null };
        }
        case "modify-grade": {
            return { ...tags, grade: action.payload, courseId: null };
        }
        case "modify-subject": {
            return { ...tags, subject: action.payload, topic: null };
        }
        case "modify-topic": {
            return { ...tags, topic: action.payload };
        }
        case "modify-exam": {
            return { ...tags, exam: action.payload, examUnit: null, examSection: null };
        }
        case "modify-examUnit": {
            return { ...tags, examUnit: action.payload, examSection: null };
        }
        case "modify-examSection": {
            return { ...tags, examSection: action.payload };
        }
        case "modify-custom": {
            return { ...tags, custom: action.payload };
        }
        default: {
            throw new Error("Unknown action: " + action);
        }
    }
};

type TaggableUserContent =
    | { note: Note; flashcardSet?: never; media?: never }
    | { flashcardSet: FlashcardSet; note?: never; media?: never }
    | { note?: never; flashcardSet?: never; media: Media };

type TaggingBodyProps = {
    tags: TagFields;
    dispatch: React.Dispatch<TagAction>;
};

const TaggingBody = ({ tags, dispatch }: TaggingBodyProps) => {
    const subjects = useSubjectsAsObject();
    const isSubjectHasTopics = (s: string) => !!subjects?.[s]?.topics?.length;

    const exams = useAllExams();
    const isExamHasUnits = (e: string) => !!Object.keys(exams?.[e]?.units || {}).length;
    const isUnitHasSections = (e: string, u: string) => !!exams?.[e]?.units?.[u]?.sections?.length;

    return (
        <FlexColumn
            style={{ gap: spacing.MD, flex: 1, marginTop: "6.7rem", paddingBottom: "5rem" }}
            data-testid="tagging-body">
            {/* SUBJECT TAGS */}
            <FlexColumn style={{ gap: "1.4rem" }}>
                <p className="bodyBold1">Subject Tags</p>
                <Flex style={{ gap: "2rem", flexWrap: "wrap", width: "100%" }}>
                    <TaggingStyleModifier
                        labelStyle={{ display: "none" }}
                        inputProps={{
                            placeholder: "Type a subject",
                            sx: {
                                backgroundColor: themeColors.neutralWhite,
                            },
                        }}>
                        <SubjectTagging
                            subject={tags.subject}
                            onChange={newSubject => dispatch({ type: "modify-subject", payload: newSubject })}
                            containerStyle={{ flex: 1, maxWidth: "30rem" }}
                        />
                    </TaggingStyleModifier>
                    {tags.subject && isSubjectHasTopics(tags.subject) && (
                        <TaggingStyleModifier
                            labelStyle={{ display: "none" }}
                            inputProps={{
                                placeholder: "Type a topic",
                                sx: {
                                    backgroundColor: themeColors.neutralWhite,
                                },
                            }}>
                            <TopicTagging
                                topic={tags.topic}
                                subject={tags.subject}
                                onChange={newTopic => dispatch({ type: "modify-topic", payload: newTopic })}
                                containerStyle={{ flex: 1, maxWidth: "30rem" }}
                            />
                        </TaggingStyleModifier>
                    )}
                </Flex>
            </FlexColumn>

            {/* EXAM TAGS */}
            <FlexColumn style={{ gap: "1.4rem" }}>
                <p className="bodyBold1">Exam Tags</p>
                <Flex style={{ gap: "2rem", flexWrap: "wrap", width: "100%" }}>
                    <TaggingStyleModifier
                        labelStyle={{ display: "none" }}
                        inputProps={{
                            placeholder: "Type an exam",
                            sx: {
                                backgroundColor: themeColors.neutralWhite,
                            },
                        }}>
                        <ExamTagging
                            exam={tags.exam}
                            onChange={newExam => dispatch({ type: "modify-exam", payload: newExam })}
                            containerStyle={{ flex: 1, maxWidth: "30rem" }}
                        />
                    </TaggingStyleModifier>
                    {tags.exam && isExamHasUnits(tags.exam) && (
                        <TaggingStyleModifier
                            labelStyle={{ display: "none" }}
                            inputProps={{
                                placeholder: "Type a unit",
                                sx: {
                                    backgroundColor: themeColors.neutralWhite,
                                },
                            }}>
                            <ExamUnitTagging
                                examUnit={tags.examUnit}
                                exam={tags.exam}
                                onChange={newUnit => dispatch({ type: "modify-examUnit", payload: newUnit })}
                                containerStyle={{ flex: 1, maxWidth: "30rem" }}
                            />
                        </TaggingStyleModifier>
                    )}
                    {tags.exam && tags.examUnit && isUnitHasSections(tags.exam, tags.examUnit) && (
                        <TaggingStyleModifier
                            labelStyle={{ display: "none" }}
                            inputProps={{
                                placeholder: "Type a sub-unit",
                                sx: {
                                    backgroundColor: themeColors.neutralWhite,
                                },
                            }}>
                            <ExamSectionTagging
                                examSection={tags.examSection}
                                exam={tags.exam}
                                examUnit={tags.examUnit}
                                onChange={newExamSection =>
                                    dispatch({ type: "modify-examSection", payload: newExamSection })
                                }
                                containerStyle={{ flex: 1, maxWidth: "30rem" }}
                            />
                        </TaggingStyleModifier>
                    )}
                </Flex>
            </FlexColumn>
            {/* CUSTOM TAGS */}
            <FlexRowAlignCenter style={{ gap: "2.4rem" }}>
                <Flex style={{ flexDirection: "column", width: "40rem", gap: "2rem" }}>
                    <FlexColumn style={{ gap: "1rem" }}>
                        <p className="bodyBold1">Custom Tags</p>
                        <CustomTagging
                            customTags={tags.custom}
                            onChange={newCustomTags => dispatch({ type: "modify-custom", payload: newCustomTags })}
                        />
                    </FlexColumn>
                </Flex>
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

type TaggingPopupProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: (tags: TagFields) => Promise<void>;
} & TaggableUserContent;

const TaggingPopup = ({
    isOpen,
    onClose,
    note,
    flashcardSet,
    media,
    onSave,
    ...taggingBodyProps
}: TaggingPopupProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const { user } = useCurrentUser();

    const item = note || flashcardSet || media;

    const [tags, dispatch] = useReducer(tagReducer, {
        schoolId: item?.schoolId || user?.schoolId || null,
        grade: item?.grade || user?.grade || null,
        courseId: item?.courseId || null,
        subject: item?.subject || null,
        topic: item?.topic || null,
        exam: item?.exam_v2 || null,
        examUnit: item?.examUnit || null,
        examSection: item?.examSection || null,
        custom: item?.tags || [],
    } as TagFields);

    const handleClose = async () => {
        setIsLoading(true);
        await onSave({ ...tags });
        setIsLoading(false);
        onClose();
    };

    const renderContent = () => (
        <FlexColumn style={{ width: "100%" }}>
            <div style={{ marginLeft: "auto", height: "4rem" }}>
                {isLoading ? (
                    <LoadingAnimation size={30} style={{ width: "fit-content" }} />
                ) : (
                    <DialogCloseBtn onClick={handleClose} />
                )}
            </div>
            <FlexColumn style={{ gap: spacing.XS, marginTop: spacing.XS }}>
                <div style={{ fontSize: "2.5rem", fontWeight: "600" }}>Edit Tags</div>
                <div style={{ fontSize: "1.8rem" }}>Use tags to help yourself and others find your files easily.</div>
            </FlexColumn>
            <TaggingBody {...taggingBodyProps} tags={tags} dispatch={dispatch} />
        </FlexColumn>
    );

    return (
        <ResponsiveDialog
            isOpen={isOpen}
            onClose={onClose}
            DialogStyle={{
                maxWidth: "85%",
                minWidth: "70%",
                borderRadius: "1.5rem",
                padding: "4rem 4rem",
                height: "90rem",
            }}
            SlideMenuStyle={{ padding: "3rem 2rem" }}>
            {renderContent()}
        </ResponsiveDialog>
    );
};

export default TaggingPopup;
