import React from "react";
import { useAllExams } from "@knowt/syncing/hooks/exams/useExams";
import TaggingAutocomplete, { TaggingLabel } from "../styled/TaggingAutocomplete";
import { FlexColumn } from "@/components/Flex";
import { OptionProps } from "@/components/InputWithMenu";

export const ExamUnitTagging = ({
    examUnit,
    exam,
    onChange,
    containerStyle,
}: {
    examUnit: string | null;
    exam: string;
    onChange: (newUnit: string | null) => void;
    containerStyle?: React.CSSProperties;
}) => {
    const value = { label: examUnit ?? "", value: examUnit ?? null };

    const examsObj = useAllExams();
    const unitsObj = examsObj?.[exam]?.units;
    const unitsArr = unitsObj ? Object.values(unitsObj) : [];

    const unitOptions: OptionProps[] = unitsArr.map(u => ({ label: u?.name as string, value: u?.name }));

    return (
        <FlexColumn style={{ gap: "1rem", ...containerStyle }}>
            <TaggingLabel htmlFor="unitinput">Unit</TaggingLabel>
            <TaggingAutocomplete
                useAutocompleteProps={{
                    options: unitOptions,
                    value: value,
                    onChange: async (_, newValue, reason) => {
                        if (reason === "selectOption") {
                            if (newValue && newValue.value !== value.value) {
                                if (typeof newValue.value === "string") {
                                    onChange(newValue.value);
                                }
                            }
                        }

                        if (reason === "clear") {
                            onChange(null);
                        }
                    },
                }}
                inputProps={{ placeholder: "Pick unit tag", id: "unitinput" }}
            />
        </FlexColumn>
    );
};

export default ExamUnitTagging;
