import MUIDialog, { DialogProps } from "@mui/material/Dialog";

/**
 * Wrapper around MUI Dialog component, with some default Knowt styling applied, like backdrop opacity and no shadow by default.
 * This component removes MUI Dialog's opinionated body styles.
 */
export default function Dialog({
    children,
    PaperProps,
    BackdropProps,
    ...rest
}: {
    children: React.ReactNode;
} & DialogProps) {
    return (
        <MUIDialog
            {...rest}
            disableScrollLock
            // get rid of MUI default dialog's opinionated body styles
            PaperProps={{
                ...PaperProps,
                style: {
                    color: "inherit",
                    boxShadow: "none",
                    borderRadius: "0",
                    maxWidth: "unset",
                    width: "fit-content",
                    ...PaperProps?.style,
                },
            }}
            slotProps={{
                backdrop: {
                    ...BackdropProps,
                    style: {
                        backdropFilter: "blur(3px) brightness(0.4)",
                        WebkitBackdropFilter: "blur(3px) brightness(0.4)",
                        ...BackdropProps?.style,
                    },
                },
            }}>
            {children}
        </MUIDialog>
    );
}
