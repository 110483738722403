import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import ReactContentEditable from "react-contenteditable";
import { themeColors } from "@/utils/themeColors";

const htmlDecode = htmlInput => {
    const doc = new DOMParser().parseFromString(htmlInput, "text/html");
    return doc.documentElement.textContent;
};

const useRefCallback = callback => {
    const ref = useRef(callback);

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    return useCallback((...args) => {
        ref.current?.(...args);
    }, []);
};

type ContentEditableProps = {
    onChange: string;
    onInput: string;
    onBlur: string;
    onKeyDown: string;
    html: string;
    style: React.CSSProperties;
    disabled: boolean;
    autoFocus: boolean;
    focusTrap: string;
    autoSelectAllTextOnFocus: boolean;
};

/**
 * A wrapper component for ContentEditable.
 * see: https://github.com/lovasoa/react-contenteditable/issues/161
 */
const ContentEditable = (
    {
        onChange,
        onInput,
        onBlur,
        onKeyDown,
        html,
        style,
        disabled,
        autoFocus,
        focusTrap,
        autoSelectAllTextOnFocus,
        ...props
    }: ContentEditableProps,
    ref: React.Ref<any>
) => {
    const innerRef = useRef();

    const onChangeRef = useRefCallback(onChange);
    const onInputRef = useRefCallback(onInput);
    const onBlurRef = useRefCallback(onBlur);
    const onKeyDownRef = useRefCallback(onKeyDown);

    const focusOnEnd = useCallback(() => {
        (innerRef.current as any).focus();
        const target = document.createTextNode("");
        (innerRef.current as any).appendChild(target);
        if (target !== null && target.nodeValue !== null) {
            const sel = window.getSelection();
            if (sel !== null) {
                const range = document.createRange();
                range.setStart(target, target.nodeValue.length);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            focus: () => (innerRef.current as any).focus(),
            focusOnEnd,
        }),
        [focusOnEnd]
    );

    useEffect(() => {
        if (autoFocus && !disabled) {
            setTimeout(() => focusOnEnd());
        }
    }, [autoFocus, disabled, focusOnEnd]);

    const autoSelectAllText = useCallback(() => {
        const range = document.createRange();
        range.selectNodeContents(innerRef.current);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }, []);

    const deselectTextSelected = useCallback(() => {
        window.getSelection().removeAllRanges();
    }, []);

    useEffect(() => {
        if (!autoSelectAllTextOnFocus) {
            return;
        }

        if (disabled) {
            deselectTextSelected();
        } else {
            const timeout = setTimeout(autoSelectAllText, 100);
            return () => clearTimeout(timeout);
        }
    }, [disabled, autoSelectAllTextOnFocus, deselectTextSelected, autoSelectAllText]);

    const handleBlur = e => {
        onBlurRef(e);

        if (focusTrap) {
            setTimeout(() => focusOnEnd());
        }
    };

    const pastePlainText = evt => {
        evt.preventDefault();
        const text = evt.clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, text);
    };

    return (
        <ReactContentEditable
            {...props}
            disabled={disabled}
            style={{
                overflowWrap: "anywhere",
                color: themeColors.neutralBlack,
                ...style,
            }}
            innerRef={innerRef}
            html={html}
            onInput={onInputRef}
            onBlur={handleBlur}
            onPaste={pastePlainText}
            onChange={(event, ...rest) => {
                event.target.value = htmlDecode(event.target.value);
                onChangeRef(event, ...rest);
            }}
            onKeyDown={(event, ...rest) => {
                event.stopPropagation();
                onKeyDownRef(event, ...rest);
            }}
        />
    );
};

export default forwardRef(ContentEditable);
