import { fetchExam, fetchExams, fetchFeaturedExamCategories } from "@/hooks/exams/utils";
import { fromEntries } from "@/utils/genericUtils";
import { Exam } from "@knowt/syncing/graphql/schema";
import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { useMemo } from "react";

export const useRawExams = (isEnabled = true) => useSWRImmutable(isEnabled && "exams", () => fetchExams()).data;

export const useExamsByCategory = ({ type }: { type: string }) =>
    useSWRImmutable(["examsByCategory", type], () => fetchFeaturedExamCategories({ type })).data;

export const useExam = (name?: string | null) =>
    useSWRImmutable(name && ["exam", name], () => fetchExam({ name })).data;

/**
 * returns all exams
 */
export const useAllExams = (isEnabled = true) => {
    const exams = useRawExams(isEnabled);

    return useMemo(() => {
        if (!exams) return {};

        return (
            fromEntries(
                exams.map(exam => {
                    const units = fromEntries((exam.units || []).map(item => [item.name || "", item]));
                    return [exam.name, { ...exam, units }];
                })
            ) ?? {}
        );
    }, [exams]);
};

export const useAllExamTypes = (): string[] => {
    const exams = useRawExams();

    return useMemo(() => {
        if (!exams) return [];

        return [...new Set(exams.map(exam => exam.type))];
    }, [exams]);
};

export const useAllExamsByTypes = () => {
    const _allExams = useAllExams();

    return useMemo(() => {
        const desiredOrder = ["AP", "IB", "GCSE"];

        const groupedExams = {};

        Object.values(_allExams).forEach(exam => {
            if (!groupedExams[exam.type]) {
                groupedExams[exam.type] = [];
            }
            groupedExams[exam.type].push(exam);
        });

        const sortedExams = {};

        desiredOrder.forEach(type => {
            if (groupedExams[type]) {
                sortedExams[type] = groupedExams[type];
            }
        });

        Object.keys(groupedExams).forEach(type => {
            if (!sortedExams[type]) {
                sortedExams[type] = groupedExams[type];
            }
        });

        return sortedExams as {
            [examName: string]: Exam[];
        };
    }, [_allExams]);
};

export const useExamsTypes = (exams: string[], unique: boolean) => {
    const allExams = useAllExams();

    if (!allExams) {
        return [];
    }

    const typesArray: string[] = [];

    exams?.forEach((exam: string) => {
        const examDetails = allExams[exam];
        if (examDetails && examDetails.type) {
            typesArray.push(examDetails.type);
        }
    });

    if (unique) {
        const uniqueTypesArray = Array.from(new Set(typesArray));
        return uniqueTypesArray;
    }

    return typesArray;
};

export const useExamsByType = (type: string): any[] => {
    const typeCategories = useExamsByCategory({ type });

    return useMemo(() => {
        if (!typeCategories) {
            return [];
        }

        let exams = [];

        typeCategories.forEach(category => {
            exams = exams.concat(category.subjects);
        });

        return exams;
    }, [typeCategories, type]);
};

export const useExamUnits = (examName: string | null) => {
    const exam = useExam(examName);

    return useMemo(() => {
        if (!exam || !examName) {
            return [];
        }

        return exam.units;
    }, [exam, examName]);
};
