import { memoize } from "nextjs-better-unstable-cache";
import { Subject } from "@knowt/syncing/graphql/schema";
import { listSubjectsMetadata } from "@/graphql/customQueries";
import { getSubject, listSubjects } from "@/graphql/queries";
import { platform } from "@/platform";
import { client } from "@/utils/SyncUtils";
import { objectWithout } from "@/utils/dataCleaning";
import { fromEntries } from "@/utils/genericUtils";
import { FrontendFeaturedCategory, getImageLink } from "../exams/utils";
import { SubjectMetadata } from "@/graphql/customSchema";
import { CATEGORY_TO_SUBJECT, SUBJECT_TO_CATEGORY } from "./constants";
import { TIME_SECONDS } from "@/utils/dateTimeUtils";

/**
 * return a list of subjects (some subjects are categories)
 */
export const fetchSubjects = memoize(
    async ({ slim = false }: { slim?: boolean } = {}) => {
        return await client
            .query({
                query: slim ? listSubjectsMetadata : listSubjects,
                variables: { input: {} },
            })
            .then(({ data }) => data.listSubjects.items)
            .catch(async error => {
                const { report } = await platform.analytics.logging();
                report(error, "fetchSubjects");
                return null;
            });
    },
    {
        persist: true,
        duration: TIME_SECONDS.DAY,
        revalidateTags: ({ slim }) => ["fetch-subjects", slim.toString()],
        log: ["dedupe", "datacache"],
        logid: "fetch-subjects",
    }
);

export const fetchSubject = memoize(
    async ({ subject }) => {
        return await client
            .query({
                query: getSubject,
                variables: { input: { subject } },
            })
            .then(({ data }) => data.getSubject)
            .catch(async error => {
                const { report } = await platform.analytics.logging();
                report(error, "fetchSubject");
                return null;
            });
    },
    {
        persist: true,
        duration: TIME_SECONDS.DAY,
        revalidateTags: ({ subject }) => ["subject", subject],
        log: ["dedupe", "datacache"],
        logid: "get-subject",
    }
);

export const subjectCategoryLookup = (subject: string | null | undefined) => {
    // TODO: turn fetchSubjects into a hook fetching by SWR, so we can dedup the calls.
    return SUBJECT_TO_CATEGORY[subject || ""] || null;
};

/**
 * returns all the subjects grouped by category
 * @returns Object<{category: {subjects: Array, ...categoryData}}>
 */
export const fetchSubjectsByCategory = () => {
    const subjects = Object.entries(SUBJECT_TO_CATEGORY).map(([subject, category]) => ({ subject, category }));

    return groupSubjectsByCategory(subjects);
};

export const fetchFeaturedSubjectCategories = () => {
    const subjectsByCategory = fetchSubjectsByCategory();
    return getFeaturedCategories(subjectsByCategory);
};

const getFeaturedCategories = subjectsByCategory => {
    return Object.values(objectWithout(subjectsByCategory, "Other")).map(
        backendToFrontendFeaturedCategory
    ) as FrontendFeaturedCategory[];
};

export const groupSubjectsByCategory = (subjects: { subject; category }[]) => {
    const categoriesWithSubjects = Object.keys(CATEGORY_TO_SUBJECT).map(category => ({
        category,
        subjects: subjects.filter(subject => subject.category === category),
    }));

    return fromEntries(categoriesWithSubjects.map(item => [item.category, item]));
};

const backendToFrontendFeaturedCategory = ({
    category,
    subjects,
}: {
    category: SubjectMetadata["category"];
    subjects: (SubjectMetadata | Subject)[];
}) => ({
    category,
    image: getImageLink(category),
    subjects: subjects.map(({ subject }) => ({
        subject,
        route: `/subject/${subjectNameToUrlComponent(category)}/${subjectNameToUrlComponent(subject)}-flashcards`,
        image: getImageLink(subject),
    })),
    route: `/subject/${subjectNameToUrlComponent(category)}-flashcards`,
});

export const subjectNameToUrlComponent = (name: string) =>
    encodeURIComponent((name || "").replace(/: /g, "_").replace(/ - /g, "=").replace(/-/g, ".").replace(/ /g, "-"));
export const subjectUrlComponentToName = (urlComponent: string | undefined) =>
    decodeURIComponent(urlComponent)?.replace(/_/g, ": ").replace(/-/g, " ").replace(/\./g, "-").replace(/=/g, " - ");

export const getSubjectNameAndType = (param: string) =>
    param
        ? {
              type: param.split("-").pop() as "notes" | "flashcards" | string,
              param: subjectUrlComponentToName(param.split("-").slice(0, -1).join("-")),
          }
        : { type: undefined, param: undefined };
