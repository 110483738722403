import { useState } from "react";
import { FlexRowAlignCenter } from "@/components/Flex";
import Tags from "@/components/Tags";
import ContentEditable from "@/components/wrappers/ContentEditable";
import CircularFilledIcon from "@/components/CircularButton/styled/CircularFilledIcon";
import { iconSizes } from "@/utils/iconProps";
import { Plus, X } from "lucide-react";
import { themeColors } from "@/utils/themeColors";

const DeleteTagBtn = ({ onClick }: { onClick: () => void }) => {
    return (
        <CircularFilledIcon
            size={iconSizes.LG}
            Icon={X}
            onClick={onClick}
            buttonColor={"#aeaeae"}
            radius={"3rem"}
            tooltip={"delete tag"}
            color={themeColors.neutralWhite}
        />
    );
};

const CustomTagging = ({
    customTags: _initialTags,
    onChange,
}: {
    customTags: string[] | null;
    onChange: (newCustomTags: string[]) => void;
}) => {
    const [newTagVal, setNewTagVal] = useState<null | string>(null);

    const customTags = _initialTags || [];

    const submitNewTag = () => {
        const newTag = newTagVal?.trim();
        if (!newTag) {
            setNewTagVal(null);
            return;
        }
        onChange([...customTags, newTag]);
        setNewTagVal("");
    };

    const deleteTag = (index: number) => {
        const newTags = customTags.filter((_, i) => i !== index);
        onChange(newTags);
    };

    return (
        <Tags>
            {({ renderTag }) => (
                <FlexRowAlignCenter style={{ gap: "0.8rem", flexWrap: "wrap" }}>
                    {customTags.map((tag, i) => {
                        return (
                            <FlexRowAlignCenter key={tag + i} style={{ position: "relative" }}>
                                {renderTag({
                                    value: tag,
                                    sx: {
                                        fontSize: "1.5rem",
                                        padding: "1.1rem 4.5rem 0.85rem 1.5rem",
                                        fontWeight: "normal",
                                        fontFamily: "var(--knowt-font-name)",
                                        display: "block",
                                        maxWidth: "31rem",
                                        height: "4rem",
                                    },
                                })}
                                <div style={{ position: "absolute", right: "0.5rem" }}>
                                    <DeleteTagBtn onClick={() => deleteTag(i)} />
                                </div>
                            </FlexRowAlignCenter>
                        );
                    })}
                    {newTagVal !== null && (
                        <ContentEditable
                            style={{
                                fontSize: "1.5rem",
                                color: "var(--color-neutral-black)",
                                backgroundColor: "var(--color-tag-back)",
                                borderRadius: "9999px",
                                padding: "0.85rem 1.5rem",
                                outline: "none",
                            }}
                            autoFocus
                            onFocus={() => setNewTagVal("")}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const newVal = e.target.value.trim();
                                setNewTagVal(newVal);
                            }}
                            onBlur={() => submitNewTag()}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (["Enter", "Escape"].includes(e.key)) {
                                    e.preventDefault();
                                    submitNewTag();
                                }
                            }}
                            html={newTagVal}
                        />
                    )}
                    <CircularFilledIcon
                        onClick={() => {
                            setNewTagVal("");
                        }}
                        buttonColor={themeColors.primary}
                        size={iconSizes.SM}
                        Icon={Plus}
                        radius={"3.8rem"}
                        color={themeColors.primaryDark}
                    />
                </FlexRowAlignCenter>
            )}
        </Tags>
    );
};

export default CustomTagging;
