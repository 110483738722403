import React from "react";
import { GRADES } from "@knowt/syncing/hooks/grades/utils";
import TaggingAutocomplete, { TaggingLabel } from "../styled/TaggingAutocomplete";
import { FlexColumn } from "@/components/Flex";
import { OptionProps } from "@/components/InputWithMenu";

export const GradeTagging = ({
    grade,
    onChange,
    containerStyle,
}: {
    grade: string | null;
    onChange: (newGrade: string) => void;
    containerStyle?: React.CSSProperties;
}) => {
    const value = { label: grade ?? "", value: grade ?? null };

    const gradeOptions: OptionProps[] = GRADES.map(grade => ({ label: grade, value: grade }));

    return (
        <FlexColumn style={{ gap: "1rem", ...containerStyle }}>
            <TaggingLabel htmlFor="gradeinput">Grade</TaggingLabel>
            <TaggingAutocomplete
                useAutocompleteProps={{
                    options: gradeOptions,
                    value: value,
                    onChange: async (_, newValue, reason) => {
                        if (reason === "selectOption") {
                            if (newValue && newValue.value !== value.value) {
                                if (typeof newValue.value === "string") {
                                    onChange(newValue.value);
                                }
                            }
                        }
                    },
                }}
                inputProps={{ placeholder: "Grade", id: "gradeinput" }}
            />
        </FlexColumn>
    );
};

export default GradeTagging;
