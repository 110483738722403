import React from "react";
import { useAllExams } from "@knowt/syncing/hooks/exams/useExams";
import TaggingAutocomplete, { TaggingLabel } from "../styled/TaggingAutocomplete";
import { FlexColumn } from "@/components/Flex";
import { OptionProps } from "@/components/InputWithMenu";

export const ExamTagging = ({
    exam,
    onChange,
    containerStyle,
}: {
    exam: string | null;
    onChange: (newExam: string | null) => void;
    containerStyle?: React.CSSProperties;
}) => {
    const value = { label: exam ?? "", value: exam ?? null };

    const examsObj = useAllExams();
    const examsArr = Object.values(examsObj);

    const examOptions: OptionProps[] = examsArr
        .filter(e => e.type)
        .map(e => ({ label: e.name, value: e.name, group: e.type }));

    return (
        <FlexColumn style={{ gap: "1rem", ...containerStyle }}>
            <TaggingLabel htmlFor="examinput">Exam</TaggingLabel>
            <TaggingAutocomplete
                useAutocompleteProps={{
                    options: examOptions,
                    groupBy: option => option.group as string,
                    value: value,
                    onChange: async (_, newValue, reason) => {
                        if (reason === "selectOption") {
                            if (newValue && newValue.value !== value.value) {
                                if (typeof newValue.value === "string") {
                                    onChange(newValue.value);
                                }
                            }
                        }

                        if (reason === "clear") {
                            onChange(null);
                        }
                    },
                }}
                inputProps={{
                    placeholder: "Pick an exam tag",
                    id: "examinput",
                }}
            />
        </FlexColumn>
    );
};

export default ExamTagging;
