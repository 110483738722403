import { FlexColumn } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";

/**
 * A normal `FlexColumn` that is used as a main container for both `Dialog` and `SlideMenu`
 */
const DialogMainContainer = ({ children, style, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn style={{ position: "relative", backgroundColor: themeColors.neutralWhite, ...style }} {...props}>
            {children}
        </FlexColumn>
    );
};

export default DialogMainContainer;
