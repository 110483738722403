"use client";

import TaggingPopup from "./components/TaggingPopup";
import type { TagFields } from "./types";
import { TaggingStyleModifier, useTaggingStyleModifierContextSelector } from "./hooks/useTaggingStyleModifierContext";
import { TaggingContextProvider, useTaggingContextSelector } from "./hooks/useTaggingContext";
import SchoolTagging from "./components/SchoolTagging";
import CustomTagging from "./components/CustomTagging";
import ExamTagging from "./components/ExamTagging";
import GradeTagging from "./components/GradeTagging";
import SubjectTagging from "./components/SubjectTagging";
import TopicTagging from "./components/SubjectTagging/TopicTagging";
import ExamUnitTagging from "./components/ExamTagging/ExamUnitTagging";
import ExamSectionTagging from "./components/ExamTagging/ExamSectionTagging";
import TaggingAutocomplete from "./components/styled/TaggingAutocomplete";

export {
    // components
    CustomTagging,
    ExamSectionTagging,
    ExamTagging,
    ExamUnitTagging,
    GradeTagging,
    SchoolTagging,
    SubjectTagging,
    TaggingPopup,
    TopicTagging,
    TaggingAutocomplete,

    // context
    TaggingStyleModifier,
    TaggingContextProvider,

    // hooks
    useTaggingStyleModifierContextSelector,
    useTaggingContextSelector,

    // types
    TagFields,
};
