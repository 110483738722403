export const LOWER_GRADES = ["Kindergarten", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"];
export const MID_GRADES = ["9th", "10th", "11th", "12th"];
export const HIGHER_GRADES = ["University/Undergrad", "Graduate", "Doctorate"];
export const LOW_MID_GRADES = [...LOWER_GRADES, ...MID_GRADES];

export const GRADES = [...LOWER_GRADES, ...MID_GRADES, ...HIGHER_GRADES];
export const EXAM_TYPES = [
    "AP",
    "SAT",
    "MCAT",
    "GRE",
    "ACT",
    "DAT",
    "IB",
    "NEET",
    "NCERT",
    "JEE",
    "CET",
    "GCSE",
    "IGCSE",
    "A_LEVELS",
    "O_LEVELS",
];
