import React, { useEffect, useState } from "react";
import { fetchSchoolsById } from "@knowt/syncing/hooks/schools/utils";
import { useVerifiedSchoolSuggestions } from "@knowt/syncing/fetchFunctions/searchSuggestions";
import TaggingAutocomplete, { TaggingLabel } from "../styled/TaggingAutocomplete";
import { OptionProps } from "@/components/InputWithMenu";
import { FlexColumn } from "@/components/Flex";
// eslint-disable-next-line no-restricted-imports
import type { SxProps } from "@mui/material";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { VerifiedSchool } from "@knowt/syncing/graphql/schema";
import { themeColors } from "@/utils/themeColors";
import toast from "react-hot-toast";

export const SchoolTagging = ({
    schoolId,
    country,
    state,
    onChange,
    title,
    containerStyle,
    sx,
}: {
    schoolId: string | null;
    onChange: (school: VerifiedSchool | undefined | null) => void;
    title?: string;
    containerStyle?: React.CSSProperties;
    sx?: SxProps;
    country?: string;
    state?: string;
}) => {
    const { organization } = useCurrentUser();
    const [schoolInput, setSchoolInput] = useState();
    const [value, setValue] = useState<OptionProps>({ label: "", value: "" });
    const [schoolSuggestionsByOrg, setSchoolSuggestionsByOrg] = useState<VerifiedSchool[]>([]);

    useEffect(() => {
        if (schoolId && schoolId !== "NONE" && !value.value) {
            fetchSchoolsById([schoolId]).then(school => {
                if (school?.length) setValue({ label: school[0].name, value: schoolId });
            });
        }

        if (organization && organization.schools.length) {
            fetchSchoolsById(organization.schools).then(schools => {
                setSchoolSuggestionsByOrg(schools);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schoolSearchSuggestions = useVerifiedSchoolSuggestions({
        query: schoolInput || "a", // starting with alphabetically first letter to get suggestions
        country: country,
        state: state,
    });

    const allSuggestions = schoolSuggestionsByOrg
        .filter(s => s.name.toLowerCase().includes((schoolInput || "").toLowerCase()))
        ?.concat(schoolSearchSuggestions?.map(s => s.data as VerifiedSchool) || []);

    // const schoolOptions = allSuggestions?.map(s => ({ label: s.name, value: s.schoolId, group: "Suggestions" })) || [];
    const groupedSuggestions =
        country && state
            ? schoolSuggestionsByOrg
                  .map(s => ({
                      label: s.name,
                      value: s.schoolId,
                      group: !schoolSuggestionsByOrg.length ? undefined : "✨ Suggested Schools",
                  }))
                  .concat(
                      schoolSearchSuggestions?.map(s => ({
                          label: s.data.name,
                          value: s.data.schoolId,
                          group: !schoolSuggestionsByOrg.length ? undefined : "🔍 Other Schools",
                      })) || []
                  )
            : [];

    return (
        <FlexColumn style={{ gap: "1rem", ...containerStyle }}>
            {title && <TaggingLabel htmlFor="schoolinput">{title}</TaggingLabel>}
            <TaggingAutocomplete
                useAutocompleteProps={{
                    onInputChange: (_, newInputValue) => setSchoolInput(newInputValue),
                    options: groupedSuggestions,
                    value: value,
                    groupBy: !schoolSuggestionsByOrg.length ? undefined : option => option.group || "",
                    // no need to filter it as the suggestions hook already does that
                    // otherwise the option of `AddCustomTagBtn` will be filtered out
                    filterOptions: (options, _) => options,
                    onChange: async (_, newValue) => {
                        if (!newValue) {
                            setValue({ label: "", value: "" });
                            onChange(null);
                        } else if (newValue.value !== value.value) {
                            setValue(newValue);
                            onChange(
                                allSuggestions.find(s => {
                                    return s.schoolId === newValue.value;
                                })
                            );
                        }
                    },
                }}
                liHeaderProps={{
                    sx: {
                        backgroundColor: themeColors.neutralWhite,
                        color: themeColors.neutralBlack,
                        fontWeight: "bold",
                    },
                }}
                inputProps={{
                    placeholder: "Enter the school name",
                    id: "schoolinput",
                    sx: { ...sx },
                    onClick: event => {
                        if (!country || !state) {
                            toast.error("Please select country and state first");
                            event.preventDefault();
                            event.stopPropagation();
                            return event.currentTarget.blur();
                        }
                    },
                }}
            />
        </FlexColumn>
    );
};

export default SchoolTagging;
